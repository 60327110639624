<template>
  <div class="sctp-container pad-tb10">
    <el-row :gutter="10">
      <el-col :span="18">
        <div class="pad-tb15 sctp-bg-white">
          <h2 style="border-left: 5px solid #f90; padding-left: 10px;">意见反馈</h2>
        </div>
        <div class="sctp-bg-white pad15">
          <el-form ref="feedBackForm" :model="feedBackForm" :rules="feedBackFormRules"
                   label-width="150px">
            <el-form-item label="反馈类型" prop="typeId">
              <el-radio v-model="feedBackForm.typeId"
                        :key="type.typeId"
                        v-for="type in feedbackTypeList"
                        :label="type.typeId"
              >{{ type.typeName }}
              </el-radio>
            </el-form-item>
            <el-form-item label="反馈内容" prop="content">
              <el-input type="textarea" :rows="5" maxlength="255" placeholder="反馈内容"
                        v-model="feedBackForm.content"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactWay">
              <el-input v-model="feedBackForm.contactWay" placeholder="手机号" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="联系邮箱" prop="email">
              <el-input v-model="feedBackForm.email" placeholder="邮箱" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="微信/QQ" prop="contact">
              <el-input v-model="feedBackForm.contact" placeholder="微信/QQ" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="图片上传">
              <el-upload
                :http-request="fileUploadFunction"
                :data="{...uploadEnum.FEEDBACK}"
                action=""
                list-type="picture-card"
                :multiple="true"
                :limit="previewImgLimit"
                :on-error="uploadFileError"
                :before-upload="beforeUpload"
                :on-preview="previewUpload"
                :on-success="previewUploadSuccess"
                :on-exceed="fileExceed"
                :on-remove="previewRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <el-carousel indicator-position="none" height="500px;"
                             :initial-index="previewImgIndex"
                             :autoplay="false" arrow="always">
                  <el-carousel-item v-for="item in picture.preview" :key="item">
                    <img style="object-fit: contain;" width="100%" height="100%" :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
              </el-dialog>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="submitForm('feedBackForm')">提交反馈
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="sctp-bg-white">
          <div class="sctp-tc" style="padding: 30px;">
            <div>
              <!--              <el-image src="" fit="cover"></el-image>-->
              <i class="el-icon-service" style="font-size: 80px;"></i>
            </div>
            <div>
              <el-button
                style="background: red; color: #fff; padding: 8px; width: 150px; margin-top: 10px;">
                在线客服
              </el-button>
            </div>
            <div>
              <el-button
                style="background: red; color: #fff; padding: 8px; width: 150px; margin-top: 10px;">
                QQ联系
              </el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";
import validator from "../../resources/js/async-validator";

const config = require('../../resources/js/config');
export default {
  components: {
    GlobalHeader: () => import('../../components/common/GlobalHeader'),
    SearchBar: () => import('./HomeSearchBar'),
    GlobalFooter: () => import('../../components/common/GlobalFooter')
  },
  data() {
    return {
      uploadEnum,
      feedbackTypeList: [{
        typeId: 1,
        typeName: '功能异常'
      }, {
        typeId: 2,
        typeName: '体验问题'
      }, {
        typeId: 3,
        typeName: '新功能建议'
      }, {
        typeId: 4,
        typeName: '其他'
      }],
      feedBackForm: {
        typeId: null,
        content: null,
        contactWay: null,
        email:null,
        contact:null,
        userId: null,
        imgurllist: []
      },
      feedBackFormRules: {
        typeId: [
          {
            required: true,
            message: '请选择反馈类型',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '需求描述不能为空',
            trigger: 'blur'
          }
        ],
        contactWay: [
          {
            required: true,
            message: '联系方式不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
                if (!/^1[3456789]\d{9}$/.test(value)){
                  callback(new Error('输入的手机号格式有误'));
                }
              callback()

            },
            trigger: 'blur'
          },
        ],
        email:[
          {
            validator: (rule, value, callback) => {
              if (value){
                let emailVal = /^([A-Za-z0-9_.])+@([A-Za-z0-9_.])+\.([A-Za-z]{2,4})$/;
                if (!emailVal.test(value)) {
                  callback(new Error('输入的邮箱格式有误'));
                }
              }
              callback()
            },trigger: 'blur'}
        ],
        contact:[
          {
            validator: (rule, value, callback) => {
              if (value){
                if (/^[A-Za-z]/.test(value)){
                  let vals = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
                  if (!vals.test(value)) {
                    callback(new Error('输入的微信号格式有误'));
                  }else {
                    callback()
                  }
                }
                if (!/^[1-9][0-9]{4,10}$/.test(value)){
                  callback(new Error('输入的qq号格式有误'))
                }
              }
              callback()
            },trigger: 'blur'}
        ]
      },
      picture: {
        preview: []
      },
      dialogVisible: false,
      previewImgIndex: 0,
      previewImgLimit: 3,
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.post({
            ...this.feedBackForm,
            reqcode: '1044',
            reqdesc: '添加意见反馈'
          }).then(res => {
            this.$message.success('反馈成功')
            this.$router.push('/')
          })
        } else {
          this.$message.warning('请检查表单')
        }
      })
    }, uploadFileError() {
      this.$message.error('文件上传失败')
    }, beforeUpload(file) {
      const isImage = file.type.startsWith('image');
      const isLtSize = file.size / 1024 / 1024 < 1;
      if (!isImage) {
        this.$message.error('只能上传图片格式!')
      }
      if (!isLtSize) {
        this.$message.error('图片大小不能超过1M!')
      }
      return isImage && isLtSize && this.$message('文件正在上传') && true
    }, previewUpload(file) {
      if (file.response && file.response.src) {
        const path = file.response.src;
        this.feedBackForm.imgurllist = this.picture.preview.findIndex(item => item === path) || 0
      } else {
        const path = file.url;
        this.feedBackForm.imgurllist = this.picture.preview.findIndex(item => item === path) || 0
      }
      this.dialogVisible = true
    },
    previewUploadSuccess(res) {
      if (res.code) {
        const {src} = res;
        this.feedBackForm.imgurllist.push(src);
        this.picture.preview.push( src);
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
      this.$refs.feedBackForm.validateField('imgurl')
    },
    previewRemove(file) {
      if (file.response || file.url) {
        let path = (file.response && file.response.src) || file.url;
        path = path.substring(path.indexOf('/upload/'));
        const index = this.feedBackForm.imgurl.findIndex(item => item === path);
        this.feedBackForm.imgurllist.splice(index, 1);
        this.picture.preview.splice(index, 1)
      }
    }, fileExceed(files, fileList) {
      this.$message.error(`最多上传${this.previewImgLimit}张图片`)
    },
  },
  beforeMount() {
    this.feedBackForm.userId = (this.user && this.user.userId) || null;
  }
}
</script>

<style scoped>
/*@formatter:off*/
    /*@formatter:on*/
</style>
